import "./App.css";
import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import Login from "./pages/Login";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false); // 토큰이 없을때
    }
  }, []);

  const onLogin = () => {
    setIsLoggedIn(true);
    navigate("/Arbitrum");
  };

  return (
    <div className="App">
      <title>Miracle Manager</title>
      {isLoggedIn ? (
        <Header setIsLoggedIn={setIsLoggedIn} />
      ) : (
        <Login onLogin={onLogin} />
      )}
    </div>
  );
}

export default App;
