import "../styles/Main.scss";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
// 이미지
import searchIcon from "../assets/images/searchIcon.png";
import checkImg from "../assets/images/check.png";
import kycNone from "../assets/images/kycNone.png";

import DeleteModal from "../components/DeleteModal";
import TokenType from "../components/TokenType";

function Main() {
  const serverApi = process.env.REACT_APP_AWS_API;
  const location = useLocation();
  const navigate = useNavigate();
  // ===================================================
  // 유저 상세정보 조회
  // ===================================================
  // 네트워크 선택
  const { network } = useParams();
  console.log("network", network);
  // search_keyword
  const [searchKeyword, setSearchKeyword] = useState("");
  const [urlSearchKeyword, setUrlSearchKeyword] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCnt, setTotalCnt] = useState(0);
  const pagesPerBlock = 5; // 한 번에 보여질 페이지 수
  const [pageNumbers, setPageNumbers] = useState([]);

  useEffect(() => {
    // 페이지 번호 계산
    const startPage =
      Math.floor((activePage - 1) / pagesPerBlock) * pagesPerBlock + 1;
    // 페이지 번호 배열 생성
    const newPageNumbers = Array.from(
      { length: pagesPerBlock },
      (_, i) => startPage + i
    ).filter((page) => page <= totalPages);

    setPageNumbers(newPageNumbers);
  }, [activePage, totalPages]);

  useEffect(() => {
    console.log(`Total Pages Updated: ${totalPages}`);
  }, [totalPages]);

  // 유저리스트 조회
  const [userList, setUserList] = useState([]);
  // 유저리스트 아이디 저장
  const [userListId, setUserListId] = useState();
  // 토큰 정보
  const token = Cookies.get("token");
  // URL을 업데이트하는 함수
  const updateUrl = (searchKeyword, activePage) => {
    const searchParams = new URLSearchParams();
    console.log(searchParams, "searchParams");
    // searchKeyword가 유효한 값인 경우에만 쿼리 파라미터에 추가
    if (searchKeyword) {
      searchParams.append("search_keyword", searchKeyword);
    }
    searchParams.append("page", activePage);
    // navigate 함수를 사용해 URL 업데이트
    navigate(`/${network}?${searchParams.toString()}`);
  };
  // 유저리스트 조회 함수
  const getUserList = async () => {
    try {
      const res = await axios.get(
        `${serverApi}/api/cms/user/list?search_keyword=${urlSearchKeyword}&page=${activePage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // 유저 리스트와 기타 필요한 상태 업데이트
      setUserList(res.data.data_list);
      setTotalCnt(res.data.total_cnt);
      setTotalPages(Math.ceil(res.data.total_cnt / 20));
      console.log("어드민유저리스트조회", res.data);
      // 필요한 경우, 총 페이지 수 등의 상태도 여기서 업데이트
    } catch (error) {
      console.error("유저리스트 조회 에러", error);
    }
  };
  // 검색 실행 함수
  const performSearch = (keyword) => {
    setUrlSearchKeyword(keyword);
    setActivePage(1); // 검색을 실행할 때는 항상 첫 페이지로 리셋
    updateUrl(keyword, 1);
  };
  // 페이지 변경 핸들러
  const handlePageClick = (pageNumber) => {
    setActivePage(pageNumber);
    updateUrl(urlSearchKeyword, pageNumber);
  };
  // 검색 버튼 클릭 핸들러
  const onSearchButtonClick = () => {
    performSearch(searchKeyword); // 현재 검색 키워드 상태를 사용
  };
  // URL 변경 시 상태 업데이트
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get("search_keyword") || "";
    const page = parseInt(queryParams.get("page"), 10) || 1;
    if (urlSearchKeyword !== search) {
      setUrlSearchKeyword(search); // URL에서 변경된 검색어를 상태에 반영
    }
    setActivePage(page);
    getUserList();
  }, [location.search, network, urlSearchKeyword]);
  // URL 이 network?page=1 일경우 searchKeyword 초기화
  useEffect(() => {
    if (location.search === "?page=1") {
      setSearchKeyword("");
    }
  }, [location.search]);
  // ===================================================
  const [deleteShowModal, setDeleteShowModal] = useState();
  // Delete 모달
  const handleDeleteClick = (userId) => {
    setUserListId(userId);
    setDeleteShowModal(true);
  };
  const handleDeleteModal = () => {
    setDeleteShowModal(false);
  };
  console.log("location", location.search);
  console.log("searchKeyword", searchKeyword);

  return (
    <>
      <div className="main">
        <div className="main--inner">
          <div className="header--box">
            <div className="main--title">
              유저관리
              <TokenType tokenType={network} />
            </div>
            <div className="search--box">
              <input
                type="text"
                placeholder="Type here..."
                value={searchKeyword}
                onChange={(event) => setSearchKeyword(event.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    performSearch(event.target.value);
                  }
                }}
              />
              <button className="search--btn" onClick={onSearchButtonClick}>
                <img src={searchIcon} alt="searchIcon" />
              </button>
            </div>
          </div>
          {/* header--box --END--*/}
          <div className="tournament--list--box">
            <table>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>닉네임</th>
                  <th>등록일</th>
                  <th>마지막 로그인</th>
                  <th>지갑주소</th>
                  <th>IP주소</th>
                  <th>게임 미진행 카운트</th>
                  <th>KYC 인증</th>
                  {/* <th>Delete</th> */}
                  <th>상세보기</th>
                </tr>
              </thead>
              <tbody>
                {userList.map((userData, index) => (
                  <tr key={userData.id}>
                    <td>{totalCnt - (activePage - 1) * 20 - index}</td>
                    <td>{userData.nick_name}</td>
                    <td>{userData.create_dt}</td>
                    <td>{userData.last_login}</td>
                    <td>{userData.wallet_address}</td>
                    <td>{userData.ip_address}</td>
                    <td>{userData.game_no_ing_count}</td>
                    <td>
                      {userData.kyc_approved ? (
                        <img src={checkImg} alt="Verified" />
                      ) : (
                        <img src={kycNone} alt="Not Verified" />
                      )}
                    </td>
                    <td>
                      <Link
                        className="detail--btn"
                        to={`/user-detail/${userData.id}`}
                      >
                        상세보기
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <ul className="pagination">
            <li className="page-item prev2" onClick={() => handlePageClick(1)}>
              처음
            </li>
            <li
              className={`page-item prev ${activePage === 1 ? "disabled" : ""}`}
              onClick={() => handlePageClick(Math.max(activePage - 1, 1))}
            >
              이전
            </li>
            {pageNumbers.map((number) => (
              <li
                key={number}
                className={`page-item ${activePage === number ? "active" : ""}`}
                onClick={() => handlePageClick(number)}
              >
                {number}
              </li>
            ))}
            <li
              className="page-item next"
              onClick={() =>
                handlePageClick(Math.min(activePage + 1, totalPages))
              }
            >
              다음
            </li>
            <li
              className="page-item next2"
              onClick={() => handlePageClick(totalPages)}
            >
              끝
            </li>
          </ul>
        </div>
      </div>
      {deleteShowModal && (
        <DeleteModal
          onClose={handleDeleteModal}
          userListId={userListId}
          getUserList={getUserList}
        />
      )}
    </>
  );
}

export default Main;
