import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "../styles/Tournament.scss";
// 이미지
import searchIcon from "../assets/images/searchIcon.png";
import CancelModal from "../components/CancelModal";
import LinkModal from "../components/LinkModal";
import TokenType from "../components/TokenType";
const Tournament = () => {
  const serverApi = process.env.REACT_APP_AWS_API;
  const location = useLocation();
  const navigate = useNavigate();
  // 네트워크 선택
  const { network } = useParams();
  // search_keyword
  const [searchKeyword, setSearchKeyword] = useState("");
  const [urlSearchKeyword, setUrlSearchKeyword] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pagesPerBlock = 5; // 한 번에 보여질 페이지 수
  const [pageNumbers, setPageNumbers] = useState([]);

  useEffect(() => {
    // 페이지 번호 계산
    const startPage =
      Math.floor((activePage - 1) / pagesPerBlock) * pagesPerBlock + 1;

    // 페이지 번호 배열 생성
    const newPageNumbers = Array.from(
      { length: pagesPerBlock },
      (_, i) => startPage + i
    ).filter((page) => page <= totalPages);

    setPageNumbers(newPageNumbers);
  }, [activePage, totalPages]);
  useEffect(() => {
    console.log(`Total Pages Updated: ${totalPages}`);
  }, [totalPages]);
  // URL을 업데이트하는 함수
  const updateUrl = (searchKeyword, activePage) => {
    const searchParams = new URLSearchParams();
    // searchKeyword가 유효한 값인 경우에만 쿼리 파라미터에 추가
    if (searchKeyword) {
      searchParams.append("search_keyword", searchKeyword);
    }
    searchParams.append("page", activePage);
    // navigate 함수를 사용해 URL 업데이트
    navigate(`/tournament/${network}?${searchParams.toString()}`);
  };
  // 토너먼트 정보 조회
  const [tournamentList, setTournamentList] = useState([]);
  // 토큰 정보
  const token = Cookies.get("token");
  // 토너먼트 정보 조회 함수
  const getTournamentList = async () => {
    try {
      const res = await axios.get(
        `${serverApi}/api/cms/tournament/list?search_keyword=${urlSearchKeyword}&page=${activePage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTournamentList(res.data.data_list);
      setTotalPages(Math.ceil(res.data.total_cnt / 20)); // 토탈 페이지 다시 계산
      console.log("tournamentList", tournamentList);
      console.log("토탈확인", res);
    } catch (error) {
      console.error("토너먼트 정보 조회 에러", error);
    }
  };
  // 검색 실행 함수
  const performSearch = (keyword) => {
    setUrlSearchKeyword(keyword);
    setActivePage(1); // 검색을 실행할 때는 항상 첫 페이지로 리셋
    updateUrl(keyword, 1);
  };
  // 페이지 변경 핸들러
  const handlePageClick = (pageNumber) => {
    setActivePage(pageNumber);
    updateUrl(urlSearchKeyword, pageNumber);
  };
  // 검색 버튼 클릭 핸들러
  const onSearchButtonClick = () => {
    performSearch(searchKeyword); // 현재 검색 키워드 상태를 사용
  };
  // URL 변경 시 상태 업데이트
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get("search_keyword") || "";
    const page = parseInt(queryParams.get("page"), 10) || 1;
    if (urlSearchKeyword !== search) {
      setUrlSearchKeyword(search); // URL에서 변경된 검색어를 상태에 반영
    }
    setActivePage(page);
    getTournamentList();
  }, [location.search, network, urlSearchKeyword]);
  // URL 이 network?page=1 일경우 searchKeyword 초기화
  useEffect(() => {
    if (location.search === "?page=1") {
      setSearchKeyword("");
    }
  }, [location.search]);
  // Cancel 모달
  const [showModal, setShowModal] = useState(false);
  const [linkShowModal, setLinkShowModal] = useState(false);
  // 클릭시 토너먼트 id를 저장 상태
  const [tournamentId, setTournamentId] = useState(0);
  // Cancel 모달
  const handleCancelClick = (tournamentId) => {
    setTournamentId(tournamentId);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // link 이동 모달
  const handleLinkClick = (tournamentId) => {
    setTournamentId(tournamentId);
    setLinkShowModal(true);
  };
  const handleLinkCloseModal = () => {
    setLinkShowModal(false);
  };
  return (
    <>
      <div className="tournament">
        <div className="tournament--inner">
          <div className="header--box">
            <div className="main--title">
              토너먼트 관리
              <TokenType tokenType={network} />
            </div>
            <div className="search--box">
              <input
                type="text"
                placeholder="Type here..."
                value={searchKeyword}
                onChange={(event) => setSearchKeyword(event.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    performSearch(event.target.value);
                  }
                }}
              />
              <button className="search--btn" onClick={onSearchButtonClick}>
                <img src={searchIcon} alt="searchIcon" />
              </button>
            </div>
          </div>
          {/* header--box --END-- */}
          <div className="tournament--list--box">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>게임명</th>
                  <th>타입</th>
                  <th>점수방식</th>
                  <th>최대참가자수</th>
                  <th>개최자ID</th>
                  <th>토너먼트 제목</th>
                  <th>토너먼트 상태</th>
                  <th>Cancel 처리</th>
                  <th>링크</th>
                </tr>
              </thead>
              <tbody>
                {tournamentList.map((tournament) => (
                  <tr key={tournament.id}>
                    <td>{tournament.id}</td>
                    <td>{tournament.game_name}</td>
                    <td>{tournament.tournament_type}</td>
                    <td>{tournament.score_method}</td>
                    <td>{tournament.players}</td>
                    <td>{tournament.host_nick_name}</td>
                    <td>{tournament.tournament_name}</td>
                    <td>{tournament.status}</td>
                    <td>
                      <button
                        className="cancel--btn"
                        onClick={() => handleCancelClick(tournament.id)}
                      >
                        Cancel
                      </button>
                    </td>
                    <td>
                      <button
                        className="link--btn"
                        onClick={() => handleLinkClick(tournament.id)}
                      >
                        Link
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <ul className="pagination">
            <li className="page-item prev2" onClick={() => handlePageClick(1)}>
              처음
            </li>
            <li
              className={`page-item prev ${activePage === 1 ? "disabled" : ""}`}
              onClick={() => handlePageClick(Math.max(activePage - 1, 1))}
            >
              이전
            </li>
            {pageNumbers.map((number) => (
              <li
                key={number}
                className={`page-item ${activePage === number ? "active" : ""}`}
                onClick={() => handlePageClick(number)}
              >
                {number}
              </li>
            ))}
            <li
              className="page-item next"
              onClick={() =>
                handlePageClick(Math.min(activePage + 1, totalPages))
              }
            >
              다음
            </li>
            <li
              className="page-item next2"
              onClick={() => handlePageClick(totalPages)}
            >
              끝
            </li>
          </ul>
        </div>
      </div>
      {showModal && (
        <CancelModal
          onClose={handleCloseModal}
          getTournamentList={getTournamentList}
          tournamentId={tournamentId}
        />
      )}
      {linkShowModal && (
        <LinkModal
          onClose={handleLinkCloseModal}
          tournamentId={tournamentId}
          network={network}
        />
      )}
    </>
  );
};

export default Tournament;
